import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav = [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard/summary',
    icon: <CIcon icon="cil-pencil" customClassName="nav-icon" />,
    role: 'superadmin',
  },
  {
    component: 'CNavTitle',
    name: 'สมาชิก',
  },
  // {
  //   component: 'CNavGroup',
  //   name: 'สมาชิกใหม่',
  //   to: '/newcustomer',
  //   icon: 'cil-puzzle',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'รอการตรวจสอบ',
  //       to: '/customer/new',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'เพิ่มสมาชิก',
  //       to: '/customer/create',
  //     },
  //   ],
  // },
  {
    component: 'CNavGroup',
    name: 'ข้อมูลสมาชิก',
    to: '/customer',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'รายการสมาชิก',
        to: '/customer/list',
        role: 'admin,superadmin,marketing',
      },
      {
        component: 'CNavItem',
        name: 'เพิ่มสมาชิก',
        to: '/customer/create',
      },
      {
        component: 'CNavItem',
        name: 'แก้ไขข้อมูล',
        to: '/customer/edit',
        role: 'admin,superadmin,marketing',
      },
      {
        component: 'CNavItem',
        name: '@แก้ไขข้อมูล',
        to: '/customer/adminedit',
        role: 'superadmin',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'ฝาก - ถอน - เครดิต',
  },
  {
    component: 'CNavGroup',
    name: 'ข้อมูลฝากเงิน',
    to: '/transactions',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',

        name: 'รายการฝากเงิน',
        to: '/transactions/deposit/list',
        role: 'admin,superadmin',
      },
      {
        component: 'CNavItem',
        name: 'ฝากเงิน (Manual)',
        to: '/transactions/deposit/manual',
        role: 'admin,superadmin',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'ข้อมูลถอนเงิน',
    to: '/transactions',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',

        name: 'รายการถอนเงิน',
        to: '/transactions/withdraw/list',
        role: 'admin,superadmin',
      },
      {
        component: 'CNavItem',
        name: 'ถอนเงิน (Auto)',
        to: '/transactions/withdraw/auto',
        role: 'admin,superadmin',
      },
      {
        component: 'CNavItem',
        name: 'ถอนเงิน (Manual)',
        to: '/transactions/withdraw/manual',
        role: 'admin,superadmin',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'ข้อมูลเครดิต',
    to: '/transactions',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'รายการเคลียร์เครดิต',
        to: '/transactions/credit/clear',
        role: 'admin,superadmin',
      },
      {
        component: 'CNavItem',
        name: 'ตัดเครดิต',
        to: '/transactions/credit/withdraw',
        role: 'admin,superadmin',
      },
      {
        component: 'CNavItem',

        name: 'รายการคืนยอดเสีย',
        to: '/transactions/credit/returncredit',
        role: 'admin,superadmin',
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'ClearCredit',
  //   to: '/credit/clear/list',
  //   // icon: <CIcon icon="cil-pencil" customClassName="nav-icon" />,
  //   role: 'admin,superadmin',
  // },
  {
    component: 'CNavTitle',
    name: 'โบนัส',
  },
  {
    component: 'CNavGroup',
    name: 'ข้อมูลโบนัส',
    to: '/transactions',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',

        name: 'รายการโบนัส',
        to: '/bonus/list',
        role: 'admin,superadmin',
      },
      {
        component: 'CNavItem',
        name: 'เติมโบนัส',
        to: '/bonus/add',
        role: 'admin,superadmin',
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: <CIcon icon="cil-speedometer" customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Theme',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: <CIcon icon="cil-drop" customClassName="nav-icon" />,
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: <CIcon icon="cil-pencil" customClassName="nav-icon" />,
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Components',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Base',
  //   to: '/base',
  //   icon: 'cil-puzzle',
  //   items: [
  //     {
  //       component: 'CNavItem',

  //       name: 'Accordion',
  //       to: '/base/accordion',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Breadcrumb',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Carousel',
  //       to: '/base/carousels',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Collapse',
  //       to: '/base/collapses',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'List group',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Navs & Tabs',
  //       to: '/base/navs',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Pagination',
  //       to: '/base/paginations',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Progress',
  //       to: '/base/progress',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Spinners',
  //       to: '/base/spinners',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Buttons',
  //   to: '/buttons',
  //   icon: <CIcon icon="cil-cursor" customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: 'CNavItem',

  //       name: 'Buttons',
  //       to: '/buttons/buttons',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Buttons groups',
  //       to: '/buttons/button-groups',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Dropdowns',
  //       to: '/buttons/dropdowns',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Forms',
  //   icon: <CIcon icon="cil-notes" customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: 'CNavItem',

  //       name: 'Form Control',
  //       to: '/forms/form-control',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Select',
  //       to: '/forms/select',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Checks & Radios',
  //       to: '/forms/checks-radios',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Range',
  //       to: '/forms/range',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Input Group',
  //       to: '/forms/input-group',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Floating Labels',
  //       to: '/forms/floating-labels',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Layout',
  //       to: '/forms/layout',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Validation',
  //       to: '/forms/validation',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: <CIcon icon="cil-chart-pie" customClassName="nav-icon" />,
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Icons',
  //   icon: <CIcon icon="cil-star" customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: 'CNavItem',

  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Notifications',
  //   icon: <CIcon icon="cil-bell" customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: 'CNavItem',

  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Toasts',
  //       to: '/notifications/toasts',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: <CIcon icon="cil-calculator" customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Extras',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Pages',
  //   icon: <CIcon icon="cil-star" customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: 'CNavItem',

  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: 'CNavItem',

  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //    ],
  // },
]

export default _nav
