import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { add, multiply } from './../functions/auth.js'
import { CButton } from '@coreui/react'
import ReactHowler from 'react-howler'
import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'

const DefaultLayout = (props) => {
  // const [alert, setAlert] = useState(false)
  // const [isAlertOpen] = useState(true)
  // const [wdNoti, setWdNoti] = useState(0)
  // const [deNoti, setDeNoti] = useState(0)

  // const [connection, setConnection] = useState(null)
  console.log(props)
  const history = useHistory()
  const RedirectToLoginWhenRefreshTokenExpired = async () => {
    try {
      let refresh_token = localStorage.getItem('refresh_token')

      let dataToSend = {
        client_id: 'norseapi',
        grant_type: 'refresh_token',
        refresh_token: refresh_token,
      }

      let formBody = []
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key)
        let encodedValue = encodeURIComponent(dataToSend[key])
        formBody.push(encodedKey + '=' + encodedValue)
      }
      formBody = formBody.join('&')

      var loginUrl = global.BASE_API_URL + '/auth/login'

      var result = await fetch(loginUrl, {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })

      if (result.ok) {
        console.log('refresh token not expired not redirect')
        var json = await result.json()
        localStorage.setItem('access_token', json.access_token)
        localStorage.setItem('expires', Date.now() + json.expires_in * 1000)
        return true
      } else {
        console.log('refresh expired redirect')
        history.push('/login')
      }
    } catch {
      console.log('refresh expired redirect')
      history.push('/login')
    }
  }
  useEffect(() => {
    var expires = localStorage.getItem('expires')
    if (Date.now() - expires > 0) {
      console.log('token expired')
      RedirectToLoginWhenRefreshTokenExpired()
    } else {
      console.log('token not expired')
      // const connect = new HubConnectionBuilder()
      //   .withUrl(global.BASE_API_URL + '/kobauto/notifications', {
      //     skipNegotiation: true,
      //     transport: HttpTransportType.WebSockets,
      //   })
      //   .withAutomaticReconnect()
      //   .build()

      // setConnection(connect)
    }

    // function checkNotification() {
    //   let wdNoti = localStorage.getItem('withdrawNotification')

    //   let deNoti = localStorage.getItem('depositNotification')

    //   setWdNoti(wdNoti)
    //   setDeNoti(deNoti)
    // }

    // window.addEventListener('storage', checkNotification)

    return () => {
      // window.removeEventListener('storage', checkNotification)
    }
  }, [])

  // useEffect(() => {
  //   if (connection) {
  //     connection
  //       .start()
  //       .then((r) => {
  //         connection.on('DepositUnfinish', DepositUnfinishNotify)

  //         connection.on('WithdrawRequest', WithdrawRequestNotify)
  //       })
  //       .catch((error) => console.log(error))
  //   }
  // }, [connection])

  // const DepositUnfinishNotify = (message) => {
  //   console.log('Noti => DepositUnfinish')
  //   document.title = message

  //   let deNoti = parseInt(localStorage.getItem('depositNotification'))

  //   if (!deNoti || deNoti == 0) {
  //     localStorage.setItem('depositNotification', 1)
  //   } else {
  //     localStorage.setItem('depositNotification', deNoti + 1)
  //   }

  //   console.log(localStorage.getItem('depositNotification'))

  //   setAlert(true)
  // }

  // const WithdrawRequestNotify = (message) => {
  //   console.log('Noti => WithdrawRequest')
  //   document.title = message

  //   let wdNoti = parseInt(localStorage.getItem('withdrawNotification'))

  //   if (!wdNoti) {
  //     localStorage.setItem('withdrawNotification', 1)
  //   } else {
  //     localStorage.setItem('withdrawNotification', wdNoti + 1)
  //   }

  //   console.log(localStorage.getItem('withdrawNotification'))

  //   setAlert(true)
  // }

  // useEffect(() => {
  //   if (connection) {
  //     console.log(connection)
  //     connection.on('DepositUnfinish', DepositUnfinish)

  //     connection.off('WithdrawRequest', WithdrawRequest)
  //     connection.on('WithdrawRequest', WithdrawRequest)
  //   }
  // }, [isAlertOpen])

  return (
    <div>
      {/* <button */}

      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader
          wdNoti={props.wdNoti}
          deNoti={props.deNoti}
          onAlertChange={props.onAlertChange}
          onClearWdNoti={props.onClearWdNoti}
          onClearDeNoti={props.onClearDeNoti}
          // alert={isAlertOpen}
          // onAlertChange={(val) => {
          //   setAlert(true)
          // }}
        />
        <div className="body flex-grow-1 px-3">
          {/* <Button
            onClick={() => {
              setAlert(true)
            }}
          >
            tttttttt
          </Button> */}
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

DefaultLayout.propTypes = {
  eye: PropTypes.any,
  onAlertChange: PropTypes.func,
  wdNoti: PropTypes.number,
  deNoti: PropTypes.number,
  onClearWdNoti: PropTypes.func,
  onClearDeNoti: PropTypes.func,
}

export default DefaultLayout
