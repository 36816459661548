import React, { Component, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import './assets/datepickerstyles/norsedatepickerstyle.css'
import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'

import ReactHowler from 'react-howler'
import DefaultLayout from './layout/DefaultLayout'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const [alert, setAlert] = useState(false)
  const [isAlertOpen] = useState(true)
  const [wdNoti, setWdNoti] = useState(0)
  const [deNoti, setDeNoti] = useState(0)

  const [connection, setConnection] = useState(null)
  // const [messages, setMessages] = useState([])
  // const [users, setUsers] = useState([])

  // global.BASE_API_URL = 'https://norseapi.azurewebsites.net'

  // global.BASE_API_URL = 'https://autoapi.kickoff-bet.com'
  // global.BASE_API_URL = 'https://autoapi.adminkob.com'

  // global.BASE_API_URL = 'https://jokerapi.adminkob.com'

  global.BASE_API_URL = `${process.env.REACT_APP_NORSE_API_URL}`
  // console.log(process.env.NORSE_API_URL)

  const DepositUnfinishNotify = (message) => {
    console.log('Noti => DepositUnfinish')
    document.title = message

    let deNoti = parseInt(localStorage.getItem('depositNotification'))

    if (!deNoti || deNoti == 0) {
      localStorage.setItem('depositNotification', 1)
      deNoti = 1
    } else {
      localStorage.setItem('depositNotification', deNoti + 1)
      deNoti++
    }

    console.log(localStorage.getItem('depositNotification'))

    setDeNoti(deNoti)
    setAlert(true)
  }

  const WithdrawRequestNotify = (message) => {
    console.log('Noti => WithdrawRequest')
    document.title = message

    let wdNoti = parseInt(localStorage.getItem('withdrawNotification'))

    if (!wdNoti) {
      localStorage.setItem('withdrawNotification', 1)
      wdNoti = 1
    } else {
      wdNoti++
      localStorage.setItem('withdrawNotification', wdNoti)
    }

    console.log(localStorage.getItem('withdrawNotification'))
    setWdNoti(wdNoti)
    setAlert(true)
  }

  const initialNotiSetting = () => {
    let wdNoti = localStorage.getItem('withdrawNotification')
    let deNoti = localStorage.getItem('depositNotification')

    setWdNoti(wdNoti)
    setDeNoti(deNoti)
  }

  useEffect(() => {
    initialNotiSetting()
    try {
      let connect
      if (!connection) {
        connect = new HubConnectionBuilder()
          .withUrl(global.BASE_API_URL + '/kobauto/notifications', {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .withAutomaticReconnect()
          .build()
        setConnection(connect)

        connect
          .start()
          .then((r) => {
            connect.on('DepositUnfinish', DepositUnfinishNotify)
            connect.on('WithdrawRequest', WithdrawRequestNotify)
            connect.onclose((e) => {
              setConnection(null)
              // alert([])
              setWdNoti(0)
              setDeNoti(0)
            })
          })
          .catch((e) => console.log(e))
      }
    } catch (e) {
      console.log(e)
    }

    return () => {
      console.log('leave app.js')
      // connection.onclose((e) => {
      //   setConnection(null)
      //   // setMessages([])
      //   // setUsers([])
      // })
    }
  }, [])

  // const closeConnection = async () => {
  //   try {
  //     await connection.stop()
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  const onAlertChange = (val) => {
    console.log('alert changed')
    setAlert(true)
  }

  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route
              path="/"
              name="Home"
              render={(props) => (
                <DefaultLayout
                  {...props}
                  onAlertChange={onAlertChange}
                  wdNoti={parseInt(wdNoti)}
                  deNoti={parseInt(deNoti)}
                  onClearWdNoti={() => {
                    setWdNoti(0)
                  }}
                  onClearDeNoti={() => {
                    setDeNoti(0)
                  }}
                />
              )}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
      <ReactHowler
        volume={1.0}
        src="/sounds/positive-alert-2.mp3"
        playing={alert}
        onEnd={() => {
          setTimeout(() => {
            // setAlert(false)
          }, 1000)
        }}
      />
    </>
  )
}

export default App

// class App extends Component {
//   render() {
//     //global.BASE_API_URL = 'https://dylan.kasetstore.com'

//     // global.BASE_API_URL = 'https://localhost:44392'

//     // global.BASE_API_URL = 'https://norseapi.azurewebsites.net'

//     // global.BASE_API_URL = 'https://autoapi.kickoff-bet.com'
//     // global.BASE_API_URL = 'https://autoapi.adminkob.com'

//     // global.BASE_API_URL = 'https://jokerapi.adminkob.com'
//     global.BASE_API_URL = `${process.env.NORSE_API_URL}`
//     console.log(process.env.NORSE_API_URL)

//     return (
//       <HashRouter>
//         <React.Suspense fallback={loading}>
//           <Switch>
//             <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
//             <Route
//               exact
//               path="/register"
//               name="Register Page"
//               render={(props) => <Register {...props} />}
//             />
//             <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
//             <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
//             <Route
//               path="/"
//               name="Home"
//               render={(props) => <DefaultLayout {...props} eye={'blue'} />}
//             />
//           </Switch>
//         </React.Suspense>
//       </HashRouter>
//     )
//   }
// }

// export default App
