import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { useHistory } from 'react-router'
import { FaBellSlash, FaBell } from 'react-icons/fa'
import {
  CContainer,
  CHeader,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CButton,
  CBadge,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'

const AppHeader = ({ deNoti, wdNoti, onAlertChange, onClearWdNoti, onClearDeNoti }) => {
  // const [wd, setWd] = useState(0)
  // const [de, setDe] = useState(0)

  // useEffect(() => {
  //   setWd(wdNoti)
  //   setDe(deNoti)
  // }, [wdNoti, deNoti])
  const history = useHistory()
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  return (
    <CHeader className="mb-4 pb-0">
      {/* <CHeader position="sticky" className="mb-4"> */}
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>
        {/* <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon="logo" height={48} alt="Logo" />
        </CHeaderBrand> */}
        {/* <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink} activeClassName="active">
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon="cil-bell" size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon="cil-list" size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon="cil-envelope-open" size="lg" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        {/* <AppBreadcrumb /> */}

        <CHeaderNav className="ms-3">
          <CButton
            style={{ marginRight: 5 }}
            color="dark"
            onClick={() => {
              localStorage.setItem('withdrawNotification', 0)
              // setWd(0)
              onClearWdNoti()
              history.push('/transactions/withdraw/list')
            }}
          >
            ถอนมีปัญหา
            {wdNoti > 0 ? (
              <CBadge color="danger" className="ms-2">
                {wdNoti}
              </CBadge>
            ) : (
              <></>
            )}
            {/* <FaBell size={20}></FaBell> */}
            {/* {alert ? 'เตือนเปิดอยู่' : 'เตือนปิดอยู่'} */}
          </CButton>
          <CButton
            style={{ marginRight: 5 }}
            className="mb-10"
            color="dark"
            onClick={() => {
              localStorage.setItem('depositNotification', 0)
              // setDe(0)
              onClearDeNoti()
              history.push('/transactions/deposit/list')
            }}
          >
            ฝากมีปัญหา
            {deNoti > 0 ? (
              <CBadge color="danger" className="ms-2">
                {deNoti}
              </CBadge>
            ) : (
              <></>
            )}
            {/* <FaBell size={20}></FaBell> */}
            {/* {alert ? 'เตือนเปิดอยู่' : 'เตือนปิดอยู่'} */}
          </CButton>

          <CButton
            onClick={() => {
              onAlertChange(true)
            }}
          >
            <FaBell size={20}></FaBell>
            {/* {alert ? 'เตือนเปิดอยู่' : 'เตือนปิดอยู่'} */}
          </CButton>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  )
}

AppHeader.propTypes = {
  // alert: PropTypes.bool,
  onAlertChange: PropTypes.func,
  deNoti: PropTypes.number,
  wdNoti: PropTypes.number,
  onClearWdNoti: PropTypes.func,
  onClearDeNoti: PropTypes.func,
}

export default AppHeader
